import { createAction, handleActions } from 'redux-actions';
import { createId, actionSearchRooms, searchQuery } from '../Search';
import { actionAppBarFloorsInSearchUpdated, actionAppBarFloorUpdated, actionMenuSearchTypeToggled, actionMenuSearchUpdateChanged } from '../AppBar';
import { actionMapZoomFeaturesUpdated, actionMapUpdateActiveFeature } from '../Map';
import takeRight from 'lodash/takeRight';
import { defaultState } from '../Layer/LayerData';
import { actionInfoQueryAndSetData, actionInfoExpandToggled } from '../Info';

export const initialState = {
  isVisible: false,
  isExpanded: false,
  MIN_SIZE: 1,
  MAX_SIZE: 5,
  maxGroupSize: 1, // (MIN_SIZE - MAX_SIZE)
  groupBy: 'building-floor',
  sortBy: 'building',
  sortDirection: 'ascending',
  /**
  * @dataGroups: [{dataGroup1}, {dataGroup2}, ...]
  * @dataGroup: {
  *  id: group id,
  *  name: group name,
  *  filters: condition used to query room, [{filter1}, {filter2}, ...]
  *   {
  *     id: filter id
  *     type: building|room|department|person|roomtype|roomsubtype,
  *     label: filter label displayed on chip component,
  *     filterVar: depending on type, see queryRoomByFilters() in search/searchredux.js
  *   }
  *  rooms: query result from /rooms?param=value endpoint, [{room1}, {room2}, ...]
  *  loading: api query resolved status
  *  layerVisible: rooms are highlighted on floorplan
  *  groupBy: room data group by -> building-floor|building-floor-sqft|room
  *  sortBy: building|sqft
  *  sortDirection: ascending|descending,
  *  colorLayerId: 'umich-room-select-1' (from LayerData.js),
  *  color: color of layer when visible
  * }
  */
  dataGroups: [],
  /**
   * @selectFeatureRequest: {
   *  type: 'room' (a group of rooms with 1 color, room select layers) |'room-color' (1 room with 1 color, draw layer),
   *  data: [rrn1, rrn2, ...], list of room record numbers
   *  layerId: layer id in LayerData.js (umich-room-select-1, umich-room-draw-1, ...)
   * }
   */
  selectFeatureRequest: {},
  overlapSelection: [], // [rrn1, rrn2, ...]
  availableColorLayers: [
    'umich-room-select-1',
    'umich-room-select-2',
    'umich-room-select-3',
    'umich-room-select-4',
    'umich-room-select-5',
  ],
};

export const actionDataCardVisibleToggled = createAction('DATA_VISIBLE_TOGGLED');
export const actionDataCardExpandToggled = createAction('DATA_EXPAND_TOGGLED');
export const actionDataMaxGroupSizeUpdated = createAction('DATA_MAX_GROUP_SIZE_UPDATED');
export const actionDataGroupAdded = createAction('DATA_GROUP_ADDED');
export const actionDataGroupUpdated = createAction('DATA_GROUP_UPDATED');
export const actionDataGroupRemoved = createAction('DATA_GROUP_REMOVED');
export const actionDataSelectFeatureRequested = createAction('DATA_SELECT_FEATURE_REQUESTED');
export const actionDataUpdateMaxSize = createAction('DATA_UPDATE_MAX_SIZE');
export const actionDataOverlapSelectionUpdate = createAction('DATA_OVERLAP_SELECTION_UPDATED');
export const actionDataAvailableColorLayersUpdated = createAction('DATA_AVAILABLE_COLOR_LAYERS_UPDATED');

export const actionDataAddDataGroup = (data) => {
  return (dispatch, getState) => {
    const { isVisible } = getState().dataCardReducer;
    dispatch(actionDataGroupAdded(data));
    if (!isVisible) {
      dispatch(actionDataCardVisibleToggled());
      dispatch(actionDataCardExpandToggled());
    }
  }
}

export const actionDataUpdateIntersection = () => {
  return (dispatch, getState) => {
    const { dataGroups } = getState().dataCardReducer;
    if (dataGroups.length < 2) {
      dispatch(actionDataOverlapSelectionUpdate([]));
      dispatch(actionDataSelectFeatureRequested({ type: 'room', data: [], layerId: 'umich-room-select-intersect' }));
      return;
    }

    const [firstGroup, ...restGroups] = dataGroups;
    const visitedRrns = new Set(firstGroup.rooms.map(room => room.rmrecnbr));
    const overlapping = [];
    
    restGroups.forEach(group => {
      group.rooms.forEach(room => {
        const rrn = room.rmrecnbr;
        if (visitedRrns.has(rrn)) {
          overlapping.push(rrn);
        } else {
          visitedRrns.add(rrn);
        }
      });
    });

    const overlappingRrns =  [...new Set(overlapping)];
    dispatch(actionDataOverlapSelectionUpdate(overlappingRrns));
    dispatch(actionDataSelectFeatureRequested({ type: 'room', data: overlappingRrns, layerId: 'umich-room-select-intersect' }));
  }
}

// primary action, query rooms based on filters,
// creates and displays a result table
export const actionDataSearchRooms = (filters, settings = {}) => {
  return async (dispatch, getState) => {
    const currentFloor = getState().appBarReducer.floor || '01';
    const infoWindowVisible = getState().appReducer.infoVisible;
    const { dataGroups, maxGroupSize, availableColorLayers } = getState().dataCardReducer;
    const isMaxGroups = dataGroups.length && dataGroups.length === maxGroupSize;
    const groupId = getGroupId(settings);
    const { type, targetFilterId } = settings && typeof settings === 'object' ? settings : {} ;

    // set filter based on a new search, add to current search, or replace filter
    filters = setFilters(filters, groupId, type, targetFilterId, dataGroups, isMaxGroups);
    let searchId;
    let targetColorLayerId;
    
    if (groupId) {
      // update filters if a group id was specified
      searchId = groupId;
      const dataGroup = dataGroups.find(group => group.id === groupId) || {};
      targetColorLayerId = dataGroup.colorLayerId;
      dispatch(actionDataGroupUpdated({ id: groupId, filters }));
    } else if (!groupId && isMaxGroups) {
      // update the last group (recently added group) filters
      const [lastGroup] = dataGroups.slice(-1);
      searchId = lastGroup.id;
      targetColorLayerId = lastGroup.colorLayerId;
      dispatch(actionDataGroupUpdated({ id: lastGroup.id, filters }));
    } else {
      // new search if group id not provided
      searchId = createId();
      const [firstColorLayer, ...restColorLayers] = availableColorLayers;
      targetColorLayerId = firstColorLayer;
      const newDataGroup = {
        id: searchId,
        name: 'Search',
        filters,
        loading: true,
        rooms: [],
        layerVisible: true,
        groupBy: initialState.groupBy,
        sortBy: initialState.sortBy,
        sortDirection: initialState.sortDirection,
        colorLayerId: targetColorLayerId,
        color: getDefaultColor(targetColorLayerId),
      };
      dispatch(actionDataAddDataGroup(newDataGroup));
      dispatch(actionDataAvailableColorLayersUpdated(restColorLayers));
    }

    // reset
    dispatch(actionDataSelectFeatureRequested({ type: 'room', data: [], layerId: targetColorLayerId }));

    // user remove all filters
    if (!filters.length) {
      searchId && dispatch(actionDataGroupUpdated({ id: searchId, rooms: [], loading: false, layerVisible: false }));
      dispatch(actionDataUpdateIntersection());
      return;
    }

    // building only: zoom, dont highlight
    if (isBuildingSearch(filters) && filters.length && filters[0].bbox) {
      dispatch(actionMapZoomFeaturesUpdated({ type: 'bbox', data: filters[0].bbox }));
      if (!infoWindowVisible) {
        dispatch(actionInfoExpandToggled());
      }
      dispatch(actionInfoQueryAndSetData(filters[0].bldrecnbr, 'building'));
      !groupId && dispatch(actionDataGroupUpdated({ id: searchId, layerVisible: false }));
    }

    // query rooms from filters
    dispatch(actionSearchRooms(filters,
        (rooms) => {
          const floors = roomsToFloors(rooms);
          dispatch(actionDataGroupUpdated({ id: searchId, rooms, loading: false }));
          dispatch(actionAppBarFloorsInSearchUpdated(floors));
          dispatch(actionDataUpdateIntersection());

          // explicit building search only: zoom and highlight building floor
          if (isBuildingSearch(filters) && !groupId) {
            const bldrecnbr = filters[0].bldrecnbr;
            dispatch(actionMapUpdateActiveFeature({ bldrecnbr, floor: currentFloor }));        
            return;
          }
          
          // single result search: zoom and highlight
          if (rooms.length === 1) {
            dispatch(actionAppBarFloorUpdated(floors[0]));
            dispatch(actionMapZoomFeaturesUpdated({ type: 'rrn', data: roomsToRrns(rooms) }));
            if (!infoWindowVisible) {
              dispatch(actionInfoExpandToggled());
            }
            dispatch(actionInfoQueryAndSetData(rooms[0].rmrecnbr, 'room'));
          }

          // rest: only highlight
          setTimeout(() => {
            dispatch(actionDataGroupUpdated({ id: searchId, layerVisible: true })); // mark checkbox
            dispatch(actionDataSelectFeatureRequested({ type: 'room', data: roomsToRrns(rooms), layerId: targetColorLayerId })); // highlight rooms on map
          }, 1000);
        },
        (error) => {
          // API gateway result exceeds limit, ask user to include another condition
          if (error?.response?.status === 502) {
            alert("Too Many Results. Please Select a Building to Refine Your Search");
            dispatch(actionMenuSearchUpdateChanged({ groupId: searchId, type: 'add' }))
            dispatch(actionMenuSearchTypeToggled('building'));
          }
        }
      ));
  }
}

export const actionDataSearchRoomsWithUrl = (searchParams, idToken) => {
  return async (dispatch, getState) => {
    const { feature, ...params } = searchParams;
    const isBuildingAndFloorSearch = (params.fl || params.floor) && (params.brn || params.bldrecnbr);
    const isBuildingAndRoomSearch = params.add;
  
    if (isBuildingAndFloorSearch) {
      const floor = params.fl || params.floor;
      const building = params.brn || params.bldrecnbr;
      const bldrecnbr = building.startsWith(100) ? building : `100${building}`;
  
      try {
        const result = await searchQuery(`/buildings/${bldrecnbr}`, idToken);
        dispatch(actionMapZoomFeaturesUpdated({type: 'bbox', data: result.data[0].bbox }));
        dispatch(actionAppBarFloorUpdated(floor));
      } catch (e) {
        console.error(e);
        alert('URL Query Failed');
      }
  
      return;
    }
  
    if (isBuildingAndRoomSearch) {
      const [room, building] = params.add.split('@');
      const bldrecnbr = building.startsWith(100) ? building : `100${building}`;
  
      dispatch(actionDataSearchRooms([
        {type: 'room', rmnbr: room, label: room},
        {type: 'building', bldrecnbr, label: bldrecnbr},
      ]));
      return;
    }
  
    const filters = [];
    for (let attribute in params) {
      const filterType = getFilterType(attribute);
      const filterAttr = getFilterAttr(attribute);
      // since some params can have more than 1 value (array) convert all params to array
      params[attribute] = Array.isArray(params[attribute]) ? params[attribute] : [params[attribute]];
      params[attribute].forEach(value => {

        if (filterType === 'roomsubtype') {
          let re = /(\d{3})&rmsubtyp=(\d{2})/;
          const [, rmtyp, rmsubtyp] = value.match(re);
          filters.push({
            type: filterType,
            label: `${rmtyp}-${rmsubtyp}`,
            rmtyp,
            rmsubtyp
          });
        } else if (filterType && filterAttr) {
          let attributeVal = filterType === 'building' && !value.startsWith('100') ? `100${value}` : value;
          filters.push({
            type: filterType,
            label: attributeVal,
            [filterAttr]: attributeVal
          });
        }

      });
    }

    if (filters.length) {
      dispatch(actionDataSearchRooms(filters));
    } else {
      alert('URL Query Failed - Invalid Query Parameter');
    }
    return;  
  }
}

export const actionDataRemoveDataGroup = (data) => {
  return (dispatch, getState) => {
    const { availableColorLayers } = getState().dataCardReducer;
    dispatch(actionDataGroupRemoved(data.id));
    dispatch(actionDataAvailableColorLayersUpdated([...availableColorLayers, data.colorLayerId]));
    setTimeout(() => { dispatch(actionDataUpdateIntersection()) }, 1000);
  }
}

export const actionDataHideAllLayers = () => {
  return (dispatch, getState) => {
    const { dataGroups } = getState().dataCardReducer;
    dataGroups.forEach(dataGroup => {
      console.log('dataGroup', dataGroup)
      dispatch(actionDataGroupUpdated({ id: dataGroup.id, layerVisible: false }));
      dispatch(actionDataSelectFeatureRequested({ type: 'room', data: [], layerId: dataGroup.colorLayerId }));
    });
  }
}

export const dataCardReducer = handleActions({
  [actionDataCardVisibleToggled]: (state) => {
    return { ...state, isVisible: !state.isVisible };
  },
  [actionDataCardExpandToggled]: (state) => {
    return { ...state, isExpanded: !state.isExpanded };
  },
  [actionDataMaxGroupSizeUpdated]: (state, action) => {
    let maxGroupSize;
    if (action.payload > 0) {
      maxGroupSize = state.maxGroupSize < state.MAX_SIZE ? state.maxGroupSize + 1 : state.MAX_SIZE;
    } else {
      maxGroupSize = state.maxGroupSize > state.MIN_SIZE ? state.maxGroupSize - 1 : state.MIN_SIZE;
    }
    return { ...state, maxGroupSize };
  },
  [actionDataGroupAdded]: (state, action) => {
    const dataGroups = normalizeGroupSize(state, [...state.dataGroups, action.payload]);
    return { ...state, dataGroups };
  },
  [actionDataGroupUpdated]: (state, action) => {
    const dataGroups = state.dataGroups.map(data => {
      if (action.payload.id === data.id) {
        return {...data, ...action.payload };
      } else {
        return data;
      }
    });

    return { ...state, dataGroups };
  },
  [actionDataGroupRemoved]: (state, action) => {
    const dataGroups = state.dataGroups.filter(group=>group.id !== action.payload);
    return { ...state, dataGroups };
  },
  [actionDataSelectFeatureRequested]: (state, action) => {
    return { ...state, selectFeatureRequest: action.payload };
  },
  [actionDataUpdateMaxSize]: (state, action) => {
    return { ...state, maxGroupSize: action.payload };
  },
  [actionDataOverlapSelectionUpdate]: (state, action) => {
    return { ...state, overlapSelection: action.payload };
  },
  [actionDataAvailableColorLayersUpdated]: (state, action) => {
    return { ...state, availableColorLayers: action.payload };
  }
}, initialState);

// selectors
export const selectorDataSelectFeatureRequest = state => state.dataCardReducer.selectFeatureRequest;

// util
function normalizeGroupSize(state, newDataGroups) {
  const { maxGroupSize } = state;
  if (newDataGroups.length <= maxGroupSize) {
    return newDataGroups;
  }

  return takeRight(newDataGroups, maxGroupSize);
}

export function roomsToRrns(rooms) {
  return rooms.map(room=>room.rmrecnbr);
}

function roomsToFloors(rooms) {
  const floors = rooms.map(room => room.floor);
  return [...new Set(floors)];
}

function isBuildingSearch(filters) {
  return filters && filters.length === 1 &&
          filters[0].type === 'building';
}

function getGroupId(settings) {
  if (typeof settings === 'number') {
    return settings;
  } else if (settings && typeof settings === 'string' && !!settings.trim()) {
    return settings;
  }

  return settings && settings.groupId ? settings.groupId : null;
}

function formatFilters(filters) {
  const newFilters = Array.isArray(filters) ? filters : [filters];
  return newFilters.map(filter => {
    if (!filter.id) { filter.id = createId() }
    return filter;
  });
}

function setFilters(filters, groupId, type, targetFilterId, dataGroups, isMaxGroups) {
  if (!filters) { return [] }

  filters = Array.isArray(filters) ? filters : [filters];

  // new search
  if (!groupId) { return formatFilters(filters) }
  
  // update current search, filters already updated
  if (groupId && !type) { return formatFilters(filters) }

  // update the last search
  if (!groupId && isMaxGroups) { return formatFilters(filters) }

  // error
  const dataGroup = dataGroups.find(group => group.id === groupId);
  if (!groupId || !dataGroups.length || !dataGroup) { return [] }

  const currentFilters = dataGroup.filters;

  // case when adding new filter to current search
  if (groupId && type === 'add') {
    return formatFilters([...currentFilters, ...filters]);
  }

  // case when replacing target filter with new filter
  if (groupId && type === 'replace' && targetFilterId) {
    const removed = currentFilters.filter(fil => fil.id !== targetFilterId);
    return formatFilters([...removed, ...filters]);
  }

  return [];
}

export function getDefaultColor(layerId) {
  const color = {
    'umich-room-select-1': defaultState.roomSelectColor1,
    'umich-room-select-2': defaultState.roomSelectColor2,
    'umich-room-select-3': defaultState.roomSelectColor3,
    'umich-room-select-4': defaultState.roomSelectColor4,
    'umich-room-select-5': defaultState.roomSelectColor5,
  };

  return color[layerId];
}

function getFilterType(attr) {
  if (attr === 'bldrecnbr' || attr === 'brn') {
    return 'building';
  } else if (attr === 'rmrecnbr' || attr === 'rrn' || attr === 'rmnbr') {
    return 'room';
  } else if (attr === 'deptid') {
    return 'department';
  } else if (attr === 'deptocc') {
    return 'occdepartment';
  } else if (attr === 'uniqname') {
    return 'person';
  } else if (attr === 'rmsubtyp') {
    return 'roomsubtype';
  } else if (attr === 'rmtyp') {
    return 'roomtype';
  } else {
    return null;
  }
}

function getFilterAttr(attr) {
  if (attr === 'bldrecnbr' || attr === 'brn') {
    return 'bldrecnbr';
  } else if (attr === 'rmrecnbr' || attr === 'rrn') {
    return 'rmrecnbr';
  } else if (attr === 'rmnbr') {
    return 'rmnbr';
  } else if (attr === 'deptid') {
    return 'deptid';
  } else if (attr === 'deptocc') {
    return 'deptocc';
  } else if (attr === 'uniqname') {
    return 'person_uniqname';
  } else if (attr === 'rmsubtyp') {
    return 'rmsubtyp';
  } else if (attr === 'rmtyp') {
    return 'rmtyp';
  } else {
    return null;
  }
}

export function filtersToQueryParam(filters) {
  const queryParamStr = filters.reduce((queryStr, filter) => {
    if (filter.type === 'building') {
      queryStr += '&brn=' + filter.bldrecnbr;
    } else if (filter.type === 'room') {
      if (filter.rmrecnbr) {queryStr += '&rmrecnbr=' + filter.rmrecnbr}
      if (filter.rmnbr) {queryStr += '&rmnbr=' + filter.rmnbr}
    } else if (filter.type === 'department') {
      queryStr += '&deptid=' + filter.deptid;
    } else if (filter.type === 'occdepartment') {
      queryStr += '&deptocc=' + filter.deptocc;
    } else if (filter.type === 'person') {
      queryStr += '&uniqname=' + filter.person_uniqname;
    } else if (filter.type === 'roomtype') {
      queryStr += '&rmtyp=' + filter.rmtyp;
    } else if (filter.type === 'roomsubtype') {
      queryStr += '&rmtyp=' + filter.rmtyp;
      queryStr += '&rmsubtyp=' + filter.rmsubtyp;
    }
    return queryStr;
  }, '');

  return `${window.location.origin}/#feature=search${queryParamStr}`;
}